import ApiResponse from '../types/ApiResponse';
import CourseInstance, {
  CourseInstanceAttendee,
  CourseInstanceAttendeeComplete,
  CourseInstanceAttendeeStatus,
  CourseInstanceCardData,
} from '../types/CourseInstance';
import MobileCalendarDate from '../types/MobileCalendarDate';
import PaginationMeta from '../types/PaginationMeta';
import PaginationParams from '../types/PaginationParams';
import SuggestedCourseInstanceCount from '../types/SuggestedCourseInstanceCount';
import User from '../types/User';

import client from './client';

type CourseInstanceFilter = {
  instructorId?: User['id'];
  startsAt?: string;
  endsAt?: string;
};

export const getCourseInstances = (
  filter?: CourseInstanceFilter,
  paginationParams?: PaginationParams | null,
) =>
  client.get<ApiResponse<CourseInstanceCardData[], PaginationMeta>>(
    '/course-instances/mobile',
    {
      params: {
        ...filter,
        ...paginationParams,
      },
    },
  );

export const getCourseInstance = (id: CourseInstance['id']) =>
  client.get<CourseInstance>(`/course-instances/mobile/${id}`);

export const getCourseInstancesAttendees = (id: CourseInstance['id']) =>
  client.get<CourseInstanceAttendee[]>(`/course-instances/${id}/attendees`);

export type CreateCourseInstanceAttendeeParams = {
  name: string;
  email?: string;
  status?: CourseInstanceAttendeeStatus;
  comment?: string;
};

export const createCourseInstanceAttendees = (
  id: CourseInstance['id'],
  attendees: CreateCourseInstanceAttendeeParams[],
) =>
  client.post<CourseInstanceAttendeeComplete[]>(
    `/course-instances/${id}/attendees`,
    { attendees },
  );

export type UpdateCourseInstanceAttendeeParams = {
  id: number;
  name: string;
  email?: string;
  status?: CourseInstanceAttendeeStatus;
  comment?: string;
};

export const updateCourseInstanceAttendees = (
  id: CourseInstance['id'],
  attendees: UpdateCourseInstanceAttendeeParams[],
) =>
  client.patch<CourseInstanceAttendeeComplete[]>(
    `/course-instances/${id}/attendees`,
    {
      attendees,
    },
  );

export const deleteCourseInstancesAttendees = (
  id: CourseInstance['id'],
  attendeeIds: CourseInstanceAttendee['id'][],
) =>
  client.delete(
    `/course-instances/${id}/attendees?ids=${attendeeIds.join(',')}`,
  );

export type GetMobileCalendarParams = {
  instructorId: number;
  startDate: string;
  endDate: string;
};

export const getMobileCalendar = (params: GetMobileCalendarParams) =>
  client.get<MobileCalendarDate[]>(`/course-instances/mobile/calendar`, {
    params: { ...params },
  });

export const getSuggestedCourseInstanceCount = (
  params: GetMobileCalendarParams,
) =>
  client.get<SuggestedCourseInstanceCount>(
    `course-instances/mobile/suggested-course-instance-count`,
    { params: { ...params } },
  );

type AcceptPotentialCourseInstanceParams = {
  userId: number;
};

export const acceptCourseInstance = (
  id: CourseInstance['id'],
  params: AcceptPotentialCourseInstanceParams,
) => client.patch(`/course-instances/mobile/${id}/accept`, params);
