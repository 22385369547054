import { CloseOutlined, SwapRightOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { Picker } from 'antd-mobile';
import {
  PickerColumn,
  PickerValue,
} from 'antd-mobile/es/components/picker-view';
import { range } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Warning from '../resources/img/warning_icon.svg';

import SafeLifeSpin from './SafeLifeSpin';

const earliestHour = 7;
const latestHour = 22;

export type TimeBlock = {
  hour: number;
  minute: number;
};

export type TimeInterval = {
  id?: number;
  start: TimeBlock;
  end: TimeBlock;
};
export const defaultTimeSlot: TimeInterval = {
  start: { hour: earliestHour, minute: 0 },
  end: { hour: earliestHour, minute: 0 },
};

export const stringifyTime = (time: TimeBlock) => {
  return `${time.hour.toString().padStart(2, '0')}:${time.minute
    .toString()
    .padStart(2, '0')}`;
};

type TimeIntervalPickerProps = {
  selectedIntervals: TimeInterval[];
  setSelectedIntervals?: React.Dispatch<React.SetStateAction<TimeInterval[]>>;
  onAddInterval?: (timeInterval: Omit<TimeInterval, 'id'>) => void;
  loadingAdd?: boolean;
  onUpdateInterval?: (
    id: number,
    type: 'start' | 'end',
    time: TimeBlock,
  ) => void | Promise<void>;
  loadingUpdate?: boolean;
  onDeleteInterval?: (id: number) => void | Promise<void>;
  /** ID of interval currently being deleted, defaults to row if intervals don't have IDs */
  loadingDeleteId?: number | null;
  requireConfirmDelete?: boolean;
  maxIntervals?: number;
};

const TimeIntervalPicker: React.FC<TimeIntervalPickerProps> = ({
  selectedIntervals,
  onAddInterval,
  loadingAdd = false,
  onUpdateInterval,
  loadingUpdate = false,
  onDeleteInterval,
  loadingDeleteId = null,
  requireConfirmDelete = false,
  maxIntervals = Number.MAX_SAFE_INTEGER,
}) => {
  const { t } = useTranslation();
  const [deleteModalOpen, setDeleteModalOpen] = useState<number | null>(null);
  const [pickerOpen, setPickerOpen] = useState<{
    row: number;
    type: 'start' | 'end';
  }>();

  const pickerValues: { hour: PickerColumn; minute: PickerColumn } = {
    hour: range(earliestHour, latestHour).map((hour) =>
      String(hour).padStart(2, '0'),
    ),
    minute: ['00', '30'],
  };

  const getPickerColumns = () => {
    if (!pickerOpen) return [];
    if (pickerOpen.type === 'start')
      return [pickerValues.hour, [':'], pickerValues.minute];

    const { hour: startHour, minute: startMinute } =
      selectedIntervals[pickerOpen.row].start;
    return [
      pickerValues.hour.filter((hour) =>
        startMinute === 0
          ? Number(hour) >= startHour
          : Number(hour) > startHour,
      ),
      [':'],
      pickerValues.minute,
    ];
  };

  const onPickerConfirm = (value: PickerValue[]) => {
    if (!pickerOpen || !onUpdateInterval) return;
    const { row, type } = pickerOpen;
    const currentValue = selectedIntervals[row];

    const [hour, minute] = [Number(value[0]), Number(value[2])];
    const res = onUpdateInterval(currentValue.id ?? row, type, {
      hour,
      minute,
    });
    if (res instanceof Promise) {
      res.finally(() => setPickerOpen(undefined));
    } else {
      setPickerOpen(undefined);
    }
  };

  const onConfirmDelete = () => {
    if (deleteModalOpen === null || !onDeleteInterval || loadingDeleteId)
      return;
    const res = onDeleteInterval(deleteModalOpen);
    if (res instanceof Promise) {
      res.finally(() => setDeleteModalOpen(null));
    } else {
      setDeleteModalOpen(null);
    }
  };

  const onCancelDelete = () => {
    if (loadingDeleteId) return;

    setDeleteModalOpen(null);
  };

  return (
    <div className="w-full flex flex-col items-center relative">
      <div className="w-full border-solid border-0 border-t-1 border-gray-400">
        {!!pickerOpen && (
          <Picker
            visible={true}
            columns={getPickerColumns()}
            loading={loadingUpdate}
            value={
              !selectedIntervals.length
                ? undefined
                : pickerOpen.type === 'start'
                  ? [
                      selectedIntervals[pickerOpen.row].start.hour
                        .toString()
                        .padStart(2, '0'),
                      ':',
                      selectedIntervals[pickerOpen.row].start.minute
                        .toString()
                        .padStart(2, '0'),
                    ]
                  : [
                      selectedIntervals[pickerOpen.row].end.hour
                        .toString()
                        .padStart(2, '0'),
                      ':',
                      selectedIntervals[pickerOpen.row].end.minute
                        .toString()
                        .padStart(2, '0'),
                    ]
            }
            cancelText="Cancel"
            confirmText="Confirm"
            onConfirm={onPickerConfirm}
            onCancel={() => setPickerOpen(undefined)}
          />
        )}
        {selectedIntervals.map((values, row) => {
          const loadingDelete: boolean = loadingDeleteId === values.id ?? row;
          return (
            <div
              key={row}
              className="w-full flex flex-row items-center gap-2.5 p-2 border-solid border-0 border-b-1 border-gray-400">
              <Button
                disabled={!onUpdateInterval || loadingDelete}
                className="grow-default"
                style={
                  !onUpdateInterval
                    ? {
                        border: 'none',
                        color: 'black',
                        background: 'transparent',
                        flexGrow: 1,
                        cursor: 'default',
                      }
                    : undefined
                }
                onClick={() =>
                  onUpdateInterval && setPickerOpen({ row, type: 'start' })
                }>
                {stringifyTime(values.start)}
              </Button>
              <SwapRightOutlined className="text-gray-600 text-xl" />
              <Button
                disabled={!onUpdateInterval || loadingDelete}
                className="grow-default"
                style={
                  !onUpdateInterval
                    ? {
                        border: 'none',
                        color: 'black',
                        background: 'transparent',
                        flexGrow: 1,
                        cursor: 'default',
                      }
                    : undefined
                }
                onClick={() =>
                  onUpdateInterval && setPickerOpen({ row, type: 'end' })
                }>
                {stringifyTime(values.end)}
              </Button>
              {!!onDeleteInterval &&
                (loadingDelete ? (
                  <SafeLifeSpin />
                ) : (
                  <Button
                    className="border-0 bg-blueGrayMedium text-blue-800"
                    shape="circle"
                    size="small"
                    icon={<CloseOutlined />}
                    onClick={() =>
                      requireConfirmDelete
                        ? setDeleteModalOpen(values.id ?? row)
                        : onDeleteInterval(values.id ?? row)
                    }
                  />
                ))}
            </div>
          );
        })}
      </div>
      {loadingAdd ? (
        <SafeLifeSpin className="mt-1" />
      ) : (
        !!onAddInterval &&
        selectedIntervals.length < maxIntervals && (
          <Button
            type="link"
            className="border-none"
            style={{ border: 'none' }}
            disabled={
              !!selectedIntervals.length &&
              stringifyTime(
                selectedIntervals[selectedIntervals.length - 1].end,
              ) <=
                stringifyTime(
                  selectedIntervals[selectedIntervals.length - 1].start,
                )
            }
            onClick={() => onAddInterval({ ...defaultTimeSlot })}>
            {t('components.IntervalPicker.addAvailability')}
          </Button>
        )
      )}
      <Modal
        open={deleteModalOpen !== null}
        onCancel={onCancelDelete}
        onOk={onConfirmDelete}
        confirmLoading={loadingDeleteId !== null}
        centered>
        <div className="flex flex-row items-center">
          <img src={Warning} className="py-2 pr-3" />
          <div className="text-base font-medium">
            {t('components.IntervalPicker.confirmDelete')}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TimeIntervalPicker;
